import React, { useEffect, useState } from "react";
import roundFunction from "../utils/roundFunction";
import { useDispatch, useSelector } from "react-redux";
import { Bar, Cell, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { setQuarter, setSingle, setYear } from "../actions/sectionsActions";
import BigGraph from "../componentsGeneral/section/BigGraph";
import GraphTicks from "../componentsGeneral/section/GraphTicks";
import {
  ageColors,
  ethnicityColors,
  exitReasonColors,
  genderColors,
  tenureColors,
  absenteeismColors,
  vacanciescolors,
  attritioncolors,
  avgDaysHire,
} from "../constants/colors";
import { setItFinanceCloudDate } from "../actions/itDashFinaceActions";
import formatNumber from "../utils/formatNumber";
import FeatherIcon from "feather-icons-react";
import BarShapeItTm from "./BarshapeItTm";
import ScrollButton from "../componentsGeneral/section/ScrollButton";
import GraphInfo from "../componentsGeneral/section/GraphInfo";
import { getProperty } from "../utils/dataMappingTurnover";
import CustomDot from "../componentsGeneral/section/CustomDot";
// import TooltipTm from './TooltipTm'
import GraphXTitles from "../componentsGeneral/section/GraphXTitles";
import useTooltipControl from "../hooks/useTooltipControl";
import { ConsoleView, isMobile } from "react-device-detect";
import TooltipTm from "./ToolTipTm";
import Graphxtitle from "./Graphxtitle";

const BigGraphItTeamMembers = ({
  dataKey,
  trend2year,
  attritiontabs,
  year1,
  reasonline,
  activetab,
}) => {
  const dispatch = useDispatch();
  const {
    activeSection,
    isSingle,
    activeTab,
    fiscDateEmployee,
    activeQYSwitcher,
    fiscDateEmployeeAbsenteesim,
    year,
    quarter,
    bars,
    itFinanceCloudDate,
  } = useSelector((state) => ({
    activeSection: state.sections.activeSection,
    isSingle: state.sections.isSingle,
    activeTab: state.sections.activeTab,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    year: state.sections.year,
    quarter: state.sections.quarter,
    bars: state.values.bars,
    itFinanceCloudDate: state.values.itFinanceCloudDate,
  }));

  const graphHeight = 310;
  const [graphRef, setGraphRef] = useState(null);
  const [data, setData] = useState([]);
  const [buttonsState, setButtonsState] = useState({
    showLeft: true,
    showRight: false,
  });

  const [attrdata, setAttrdata] = useState();

  const { onMouseOverBar, onMouseLeaveBar, isTooltipActive } =
    useTooltipControl();

  const getDefObject = () => {
    if (activeTab === "Gender") return genderColors;
    else if (activeTab === "Ethnicity") return ethnicityColors;
    else if (activeTab === "AgeGroup") return ageColors;
    else if (activeTab === "Tenure") return tenureColors;
    else if (activeTab === "ExitReason") return exitReasonColors;
    if (activeSection === "IT_Hr_TOTAL_ATTRITION") return;
  };

  const fillBars = (
    data,
    startWeekOfPeriod,
    totalIteratios,
    callback = () => {}
  ) => {
    for (let i = 0; i < totalIteratios; i++) {
      const currentPeriod = startWeekOfPeriod + i;
      let currentPeriodObj = data.find(
        ({ period }) => parseInt(period) === currentPeriod
      );
      if (!currentPeriodObj) {
        currentPeriodObj = { [dataKey]: 0, period: currentPeriod };
        data.splice(i, 0, currentPeriodObj);
      } else {
        data[i] = { ...currentPeriodObj };
      }

      callback(data, i);
    }
  };

  const getData = () => {
    let data = bars.slice(0);
    let period1 = 0;
    if (activeQYSwitcher === "Y") {
      const startWeekOfPeriod = 1;
      const cb = (data, i) => {
        if (activeSection == "s") {
          const overallData = trend2year.find(
            ({ fiscYear, fiscMonth }) =>
              year == fiscYear && fiscMonth == startWeekOfPeriod + i
          );
          const overallTurnover = overallData
            ? overallData.turnoverRate * 100
            : null;

          data[i]["overallTurnover"] = 0;
        } else if (activeSection == "IT_Hr_Vacancies") {
          const openPositionWoReqGt14Days = bars.map((date) =>
            data[i]["vacancies"] > 0
              ? (data[i]["openPositionWoReqGt14Days"] =
                  date.openPositionWoReqGt14Days)
              : (data[i]["openPositionWoReqGt14Days"] = null)
          );
          data[i]["vacancies"] == 0
            ? (data[i]["vacancies"] = null)
            : (data[i]["vacancies"] = data[i]["vacancies"]);
        } else if (activeSection == "IT_Hr_AvgDaysHire") {
          data[i]["avgDaysToHire"] = Math.round(data[i]["avgDaysToHire"]);
        }
      };
      fillBars(data, startWeekOfPeriod, 12, cb);
    } else {
      const firstPeriod = data[0] ? data[0].period : (quarter - 1) * 13 + 1;
      const startWeekOfPeriod =
        Math.floor((firstPeriod === 53 ? 39 : firstPeriod - 1) / 13) * 13 + 1;
      const lastPeriod = data[data.length - 1]
        ? data[data.length - 1].period
        : 0;
      const weeksCount = lastPeriod === 53 ? 14 : 13;
      fillBars(bars, startWeekOfPeriod, weeksCount);
    }
    let dataCopy;
    if (dataKey === "IT_Hr_TOTAL_ATTRITION") {
      dataCopy = data.map((el) => Object.assign({}, el));
      const o = getDefObject();
      dataCopy.forEach((el, i, array) => {
        if (
          el.period == fiscDateEmployee.month &&
          year === fiscDateEmployee.year
        ) {
          for (let k in o) {
            el[`${dataKey}${k}Future`] = el[`${dataKey}${k}`];
            el[`${dataKey}${k}`] = null;
            if (array[i - 1]) {
              array[i - 1][`${dataKey}${k}Future`] =
                array[i - 1][`${dataKey}${k}`];
            }
          }

          el[`Overall Turnover RateFuture`] = el[`Overall Turnover Rate`];
          el[`Overall Turnover Rate`] = null;

          if (array[i - 1]) {
            array[i - 1][`Overall Turnover RateFuture`] =
              array[i - 1][`Overall Turnover Rate`];
          }
        }
      });
    }
    return dataCopy ? dataCopy : data;
  };

  const handleClickBar = (data) => {
    if (!Object.keys(data).includes("viewBy")) {
      dispatch(setSingle(false));
      return;
    }

    if (data.period == isSingle) {
      dispatch(setSingle(false));
      return;
    }

    dispatch(setSingle(parseInt(data.period)));
  };

  const formatTickValue = (value) => {
    const { prependStr, appendStr, number } = formatNumber({
      number: isNaN(value) ? 0 : value,
      needScale: activeSection !== "IT_Hr_Vaancies",
      // employeePercents: activeSection === "IT_Hr_Vacancies",
      remove$: true,
    });

    return `${number === "0" || number == 0 ? "" : prependStr}${
      number === "0" || number == 0 ? "" : number
    }${number === "0" || number == 0 ? "" : appendStr}`;
  };

  const getGraphVacancies = () => {
    const infoAbsenteesim = [
      {
        title: activeTab !== "Frozen" && "Vacancies",
        line: activeTab !== "Frozen" && (
          <span
            className="inline-block mr-2 h-3 w-3"
            style={{ border: "2px #002544 solid", background: "#002544" }}
          ></span>
        ),
      },
      {
        title:
          activeTab !== "Frozen" &&
          "Open Positions without requisitions greater than 14 days",
        line: activeTab !== "Frozen" && (
          <span
            className="inline-block mr-2 h-3 w-3"
            style={{ border: "2px #F3AF00 solid", background: "#F3AF00" }}
          ></span>
        ),
      },
      {
        title: activeTab !== "Active" && "Frozen Positions",
        line: activeTab !== "Active" && (
          <span
            className="inline-block mr-2 h-3 w-3"
            style={{ border: "2px #2699FB solid", background: "#2699FB" }}
          ></span>
        ),
      },
    ];

    return infoAbsenteesim.map((v, i) => (
      <div key={i} className="flex items-center">
        {v.line}
        <span className="text-xs text-black">{v.title}</span>
      </div>
    ));
  };

  const getGraphInfo = () => {
    const infoAbsenteesim = [
      {
        title:
          activeSection === "IT_Hr_Positions"
            ? "Positions In Offer Status"
            : activeSection === "IT_Hr_AvgDaysHire"
            ? "Avg days to hire"
            : "Vacancies",
        line:
          activeSection === "IT_Hr_Positions" ||
          activeSection === "IT_Hr_AvgDaysHire" ? (
            <>
              <span
                className="inline-block mr-2 h-3 w-3"
                style={{ border: "2px #6FB4FF solid", background: "#6FB4FF" }}
              ></span>
            </>
          ) : (
            <span
              className="inline-block mr-2 h-3 w-3"
              style={{ border: "2px #002544 solid", background: "#002544" }}
            ></span>
          ),
      },
      {
        title:
          activeSection === "IT_Hr_Positions" ||
          activeSection === "IT_Hr_AvgDaysHire"
            ? ""
            : "Open Positions without requisitions greater than 14 days",
        line:
          activeSection === "IT_Hr_Positions" ||
          activeSection === "IT_Hr_AvgDaysHire" ? (
            <></>
          ) : (
            <span
              className="inline-block mr-2 h-3 w-3"
              style={{ border: "2px #F3AF00 solid", background: "#F3AF00" }}
            ></span>
          ),
      },
      {
        title: activeSection === "IT_Hr_Vacancies" && "Frozen Positions",
        line: activeSection === "IT_Hr_Vacancies" && (
          <span
            className="inline-block mr-2 h-3 w-3"
            style={{ border: "2px #2699FB solid", background: "#2699FB" }}
          ></span>
        ),
      },
    ];

    return infoAbsenteesim.map((v, i) => (
      <div key={i} className="flex items-center">
        {v.line}
        <span className="text-xs text-black">{v.title}</span>
      </div>
    ));
  };

  const getGraphInfoAttr = () => {
    const infoAbsenteesim = [
      {
        title: attritiontabs === 0 ? "Voluntary" : "Total Current",
        line: (
          <span
            className="inline-block mr-2 h-0 w-6"
            style={{ border: "2px #002544 solid", background: "#002544" }}
          ></span>
        ),
      },
      {
        title: attritiontabs === 0 ? "Involuntary" : "Total Last Year",
        line: (
          <span
            className="inline-block mr-2 h-0 w-6"
            style={{
              border:
                attritiontabs === 0
                  ? "2px #f3af00 solid"
                  : "2px #002544 dotted",
              background: attritiontabs === 0 ? "#f3af00" : "",
            }}
          ></span>
        ),
      },
    ];

    return infoAbsenteesim.map((v, i) => (
      <div key={i} className="flex items-center">
        {v.line}
        <span className="text-xs text-black">{v.title}</span>
      </div>
    ));
  };

  const renderTicks = () => {
    if (!graphRef) return null;
    return graphRef.state.yAxisMap.left.niceTicks.map((tickValue, i) => (
      <span className="text-sm text-black leading-0" key={i}>
        {isFinite(tickValue) ? formatTickValue(tickValue) : ""}
      </span>
    ));
  };

  const getBarTm = (obj, bars) => {
    for (let key in obj) {
      bars.push(
        <Bar
          dataKey={`${dataKey}${key}`}
          stackId="a"
          onClick={handleClickBar}
          key={key}
          yAxisId="left"
          shape={<BarShapeItTm />}
          barSize={20}
          onMouseOver={onMouseOverBar}
          onMouseLeave={onMouseLeaveBar}
        >
          {data.map((entry, index) => (
            <Cell
              cursor="pointer"
              fill={obj[key].color}
              opacity={
                (isSingle && isSingle === parseInt(entry.period)) || !isSingle
                  ? 1
                  : 0.2
              }
              period={entry.period}
              key={`cell-${index}`}
              isHalfClosed={
                year == fiscDateEmployee.year &&
                entry.period >= itFinanceCloudDate.fiscMonthNum
              }
            />
          ))}
        </Bar>
      );
    }
  };

  const getBarAbs = (obj, bars) => {
    bars.push(
      <Bar
        dataKey={"attrition"}
        onClick={handleClickBar}
        key={"attrition"}
        yAxisId="left"
        shape={<BarShapeItTm />}
        barSize={20}
        fill="#6FB4FF"
        onMouseOver={onMouseOverBar}
        onMouseLeave={onMouseLeaveBar}
      >
        {data.map((entry, index) => (
          <Cell
            cursor="pointer"
            // fill={obj[key].color}
            opacity={
              (isSingle && isSingle === parseInt(entry.period)) || !isSingle
                ? 1
                : 0.2
            }
            period={entry.period}
            key={`cell-${index}`}
            isHalfClosed={
              itFinanceCloudDate.fiscYear == fiscDateEmployee.year &&
              entry.period >= itFinanceCloudDate.fiscMonthNum
            }
          />
        ))}
      </Bar>
    );
    attritiontabs === 1 &&
      bars.push(
        <Line
          type="monotone"
          dataKey={"exitEmployeesInvoluntary"}
          key={1}
          dot={false}
          cursor={false}
          stroke={"#F3AF00"}
          strokeWidth={4}
          opacity={!isSingle ? 1 : 0.4}
          yAxisId="left"
          connectNulls={true}
          isAnimationActive={true}
        />,
        <Line
          type="monotone"
          dataKey={"exitEmployeesVoluntary"}
          key={2}
          dot={false}
          cursor={false}
          stroke={"#002544"}
          strokeWidth={4}
          opacity={!isSingle ? 1 : 0.4}
          yAxisId="left"
          // strokeDasharray={[4, 4]}
          connectNulls={true}
          isAnimationActive={true}
        />
      );
    attritiontabs === 0 &&
      bars.push(
        <Line
          type="monotone"
          dataKey={"attritionLine"}
          key={1}
          dot={false}
          cursor={false}
          stroke={"#002544"}
          strokeWidth={4}
          opacity={!isSingle ? 1 : 0.4}
          yAxisId="left"
          connectNulls={true}
          isAnimationActive={true}
        />,
        <Line
          type="monotone"
          dataKey={"attritionLY"}
          key={2}
          dot={false}
          cursor={false}
          stroke={"#002544"}
          strokeWidth={4}
          opacity={!isSingle ? 1 : 0.4}
          yAxisId="left"
          strokeDasharray={[4, 4]}
          connectNulls={true}
          isAnimationActive={true}
        />
      );
  };

  const getAvgBars = (obj, bars) => {
    bars.push(
      <Bar
        dataKey={"avgDaysToHire"}
        onClick={handleClickBar}
        key={"avgDaysToHire"}
        yAxisId="left"
        shape={<BarShapeItTm />}
        barSize={20}
        fill="#6FB4FF"
        onMouseOver={onMouseOverBar}
        onMouseLeave={onMouseLeaveBar}
      >
        {data.map((entry, index) => (
          <Cell
            cursor="pointer"
            // fill={obj[key].color}
            opacity={
              (isSingle && isSingle === parseInt(entry.period)) || !isSingle
                ? 1
                : 0.2
            }
            period={entry.period}
            key={`cell-${index}`}
            isHalfClosed={
              itFinanceCloudDate.fiscYear == fiscDateEmployee.year &&
              entry.period >= itFinanceCloudDate.fiscMonthNum
            }
          />
        ))}
      </Bar>
    );
  };

  const getPositionsBars = (obj, bars) => {
    bars.push(
      <Bar
        dataKey={"PositionsInOffer"}
        onClick={handleClickBar}
        key={"PositionsInOffer"}
        yAxisId="left"
        shape={<BarShapeItTm />}
        barSize={20}
        fill="#6FB4FF"
        onMouseOver={onMouseOverBar}
        onMouseLeave={onMouseLeaveBar}
      >
        {data.map((entry, index) => (
          <Cell
            cursor="pointer"
            // fill={obj[key].color}
            opacity={
              (isSingle && isSingle === parseInt(entry.period)) || !isSingle
                ? 1
                : 0.2
            }
            period={entry.period}
            key={`cell-${index}`}
            isHalfClosed={
              itFinanceCloudDate.fiscYear == fiscDateEmployee.year &&
              entry.period >= itFinanceCloudDate.fiscMonthNum
            }
          />
        ))}
      </Bar>
    );
  };

  const getLineVc = (obj, bars) => {
    for (let key in obj) {
      activeTab === "Active" &&
        bars.push(
          <Line
            type="monotone"
            dataKey={"openPositionWoReqGt14Days"}
            key={1}
            dot={false}
            cursor={false}
            stroke={"#F3AF00"}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId="left"
            connectNulls={true}
            isAnimationActive={true}
            activeDot={{
              onMouseOver: onMouseOverBar,
              onMouseLeave: onmouseleave,
            }}
          />,
          <Line
            type="monotone"
            dataKey={"vacancies"}
            key={2}
            dot={false}
            cursor={false}
            stroke={"#002544"}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId="left"
            connectNulls={true}
            isAnimationActive={true}
            activeDot={{
              onMouseOver: onMouseOverBar,
              onMouseLeave: onmouseleave,
            }}
          />
        );
      activeTab === "Frozen" &&
        bars.push(
          <Line
            type="monotone"
            dataKey={"frozenPositions"}
            key={2}
            dot={false}
            cursor={false}
            stroke={"#2699FB"}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId="left"
            connectNulls={true}
            isAnimationActive={true}
            activeDot={{
              onMouseOver: onMouseOverBar,
              onMouseLeave: onmouseleave,
            }}
          />
        );
      activeTab === "All" &&
        bars.push(
          <Line
            type="monotone"
            dataKey={"openPositionWoReqGt14Days"}
            key={1}
            dot={false}
            cursor={false}
            stroke={"#F3AF00"}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId="left"
            connectNulls={true}
            isAnimationActive={true}
            activeDot={{
              onMouseOver: onMouseOverBar,
              onMouseLeave: onmouseleave,
            }}
          />,
          <Line
            type="monotone"
            dataKey={"vacancies"}
            key={2}
            dot={false}
            cursor={false}
            stroke={"#002544"}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId="left"
            connectNulls={true}
            isAnimationActive={true}
            activeDot={{
              onMouseOver: onMouseOverBar,
              onMouseLeave: onmouseleave,
            }}
          />,
          <Line
            type="monotone"
            dataKey={"frozenPositions"}
            key={3}
            dot={false}
            cursor={false}
            stroke={"#2699FB"}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId="left"
            connectNulls={true}
            isAnimationActive={true}
            activeDot={{
              onMouseOver: onMouseOverBar,
              onMouseLeave: onmouseleave,
            }}
          />
        );
    }
  };

  const getGraphInnerContent = (colors, content) => {
    if (activeSection === "IT_Hr_TOTAL_ATTRITION") getBarAbs(colors, content);
    else if (activeSection === "IT_Hr_TEAM_MEMBERS") getBarTm(colors, content);
    else if (activeSection === "IT_Hr_Vacancies") getLineVc(colors, content);
    else if (activeSection === "IT_Hr_AvgDaysHire") getAvgBars(colors, content);
    else if (activeSection === "IT_Hr_Positions")
      getPositionsBars(colors, content);
  };

  const getGraphContent = () => {
    const content = [];

    if (activeTab === "Gender") getGraphInnerContent(genderColors, content);
    else if (activeTab === "Ethnicity")
      getGraphInnerContent(ethnicityColors, content);
    else if (activeTab === "AgeGroup") getGraphInnerContent(ageColors, content);
    else if (activeTab === "Tenure")
      getGraphInnerContent(tenureColors, content);
    else if (activeTab === "ExitReason")
      getGraphInnerContent(exitReasonColors, content);
    if (activeSection === "ABSENTEEISM")
      getGraphInnerContent(absenteeismColors, content);
    else if (activeSection === "IT_Hr_TOTAL_ATTRITION")
      getGraphInnerContent(attritioncolors, content);
    else if (activeSection === "IT_Hr_Vacancies")
      getGraphInnerContent(vacanciescolors, content);
    else if (activeSection === "IT_Hr_AvgDaysHire")
      getGraphInnerContent(avgDaysHire, content);
    else if (activeSection === "IT_Hr_Positions")
      getGraphInnerContent(avgDaysHire, content);
    return content;
  };

  const manageButtonsState = () => {
    const currentDate =
      activeSection === "ABSENTEEISM"
        ? fiscDateEmployeeAbsenteesim
        : itFinanceCloudDate;
    if (
      activeSection === "IT_Hr_AvgDaysHire" ||
      activeSection === "IT_Hr_Vacancies" ||
      activeSection === "IT_Hr_Positions"
    ) {
      if (activeQYSwitcher == "Y") {
        setButtonsState({
          showLeft: false,
          showRight: false,
        });
      }
    } else if (
      activeSection === "IT_Hr_TOTAL_ATTRITION"
        ? itFinanceCloudDate.fiscYear == year
        : itFinanceCloudDate.fiscYear == year
    ) {
      if (activeQYSwitcher == "Y") {
        setButtonsState({
          showLeft: true,
          showRight: false,
        });
      } else {
        if (currentDate.quarter == quarter) {
          setButtonsState({
            showLeft: true,
            showRight: false,
          });
        } else {
          setButtonsState({
            showLeft: true,
            showRight: true,
          });
        }
      }
    } else if (
      activeSection === "IT_Hr_TOTAL_ATTRITION"
        ? year - 2 == itFinanceCloudDate.fiscYear
        : currentDate.fiscYear - 2 == year
    ) {
      if (activeQYSwitcher == "Y") {
        setButtonsState({
          showLeft: false,
          showRight: true,
        });
      } else {
        if (1 == quarter) {
          setButtonsState({
            showLeft: false,
            showRight: true,
          });
        } else {
          setButtonsState({
            showLeft: true,
            showRight: true,
          });
        }
      }
    } else if (activeSection == "IT_Hr_Vacancies") {
      if (activeQYSwitcher == "Y") {
        setButtonsState({
          showLeft: false,
          showRight: false,
        });
      }
    } else
      setButtonsState({
        showLeft: true,
        showRight: true,
      });
  };

  const clickLeftScroll = () => {
    if (activeSection === "IT_Hr_TEAM_MEMBERS") {
      dispatch(setSingle(12));
    } else {
      dispatch(setSingle(false));
    }
    if (activeQYSwitcher === "Y" && activeSection === "IT_Hr_TEAM_MEMBERS") {
      dispatch(setYear(year - 1));
    } else if (
      activeQYSwitcher === "Y" &&
      (activeSection === "IT_Hr_TOTAL_ATTRITION" ||
        activeSection === "IT_Hr_Vacancies")
    ) {
      dispatch(
        setItFinanceCloudDate({
          ...itFinanceCloudDate,
          fiscYear: itFinanceCloudDate.fiscYear - 1,
        })
      );
    } else {
      if (quarter == 1) {
        dispatch(setQuarter(4));
        dispatch(setYear(year - 1));
      } else {
        dispatch(setQuarter(quarter - 1));
      }
    }
  };

  const clickRightScroll = () => {
    if (activeSection === "IT_Hr_TEAM_MEMBERS") {
      if (year + 1 == itFinanceCloudDate.fiscYear)
        dispatch(setSingle(itFinanceCloudDate.fiscMonthNum));
      else dispatch(setSingle(12));
    } else {
      dispatch(setSingle(false));
    }

    if (activeQYSwitcher === "Y" && activeSection === "IT_Hr_TOTAL_ATTRITION") {
      dispatch(
        setItFinanceCloudDate({
          ...itFinanceCloudDate,
          fiscYear: itFinanceCloudDate.fiscYear + 1,
        })
      );
    } else if (
      activeQYSwitcher === "Y" &&
      activeSection === "IT_Hr_TEAM_MEMBERS"
    ) {
      dispatch(setYear(year + 1));
    } else if (
      activeQYSwitcher === "Y" &&
      activeSection === "IT_Hr_Vacancies"
    ) {
      dispatch(
        setItFinanceCloudDate({
          ...itFinanceCloudDate,
          fiscYear: itFinanceCloudDate.fiscYear + 1,
        })
      );
    } else {
      if (quarter == 4) {
        dispatch(setQuarter(1));
        dispatch(setYear(year + 1));
      } else {
        dispatch(setQuarter(quarter + 1));
      }
    }
  };

  // const renderTicks = () => {
  //   if (!graphRef) return null;

  //   return graphRef.state.yAxisMap.left.niceTicks.map((tickValue, i) => (
  //     <span className="text-sm text-black leading-0" key={i}>
  //       {isFinite(tickValue) ? formatTickValue(tickValue) : ""}
  //     </span>
  //   ));
  // };

  useEffect(() => {
    manageButtonsState();
  }, [year, itFinanceCloudDate.fiscYear]);

  useEffect(() => {
    setData(getData());
  }, [bars, dataKey, activeQYSwitcher, trend2year, year]);

  return (
    <div className="flex relative lg:overflow-visible overflow-x-scroll pt-3 pb-12 no-scrollbar">
      {activeSection === "IT_Hr_TEAM_MEMBERS" ? (
        " "
      ) : (
        <GraphInfo>
          {activeSection === "IT_Hr_TOTAL_ATTRITION"
            ? getGraphInfoAttr()
            : activeSection === "IT_Hr_Vacancies"
            ? getGraphVacancies()
            : getGraphInfo()}
        </GraphInfo>
      )}
      <div className="w-16 relative flex-shrink-0">
        <GraphTicks>{renderTicks()}</GraphTicks>
      </div>
      <div className="flex-1 relative pb-4 main-graph-container">
        {buttonsState.showLeft && (
          <ScrollButton className="left-0" onClick={clickLeftScroll}>
            <FeatherIcon className="text-gray-600" icon={"chevron-left"} />
          </ScrollButton>
        )}
        <BigGraph
          data={activeSection !== "IT_Hr_TOTAL_ATTRITION" ? data : bars}
          height={graphHeight}
          setRef={setGraphRef}
          titles={<Graphxtitle data={data} getMonth={(el) => el.period} />}
        >
          {!isMobile && (
            <Tooltip
              content={
                <TooltipTm
                  dataKey={dataKey}
                  currentObj={getDefObject()}
                  display={isTooltipActive}
                  payload={trend2year}
                  year2={itFinanceCloudDate.fiscYear}
                  activeTab={activeTab}
                ></TooltipTm>
              }
            ></Tooltip>
          )}
          {getGraphContent()}
        </BigGraph>

        {buttonsState.showRight && (
          <ScrollButton className="right-0" onClick={clickRightScroll}>
            <FeatherIcon className="text-gray-600" icon={"chevron-right"} />
          </ScrollButton>
        )}
      </div>
    </div>
  );
};

export default BigGraphItTeamMembers;
